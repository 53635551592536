import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 4×4\\@85% 1RM`}</p>
    <p>{`Barbell Rows 4×4\\@85% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`100 Burpees for time.`}</p>
    <p><em parentName="p">{`*`}{`compare to 6/7/19`}</em></p>
    <p>{`then,`}</p>
    <p>{`2 Mile Fun Run (not timed).`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`If you haven’t already please find the Crossfittheville Facebook
page and the private group created for Ville members.  We will be
sending at home workouts through this group.  If you cannot get on
Facebook please let us know and we will email them to you.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      